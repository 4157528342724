// 自动扶梯socket
import mqtt from "mqtt";
var elevators = "";
var global_callback = null;
var socket = null;
const url = "wss://iot.joylive.com:8084/mqtt";
export default {
  sendSock(agentData, callback) {
    // agentData=>socket所需参数 callback=>回调函数
    elevators = agentData;
    global_callback = callback;
    this.initSocket();
  },
  closeSocket() {
    if (socket && socket.readyState === 1) {
      socket.end();
    }
  },
  initSocket() {
    socket = mqtt.connect(url);
    socket.on("connect", () => {
      socket.subscribe("testtopic/#", { qos: 1 });
    });
    socket.on("message", (topic, message) => {
      elevators.map((item) => {
        if (topic.split("/")[1] === item.intelHardwareNumber) {
          var data = JSON.parse(message.toString());
          if (data.devices) {
            // 4个值：1故障，2检修，3上行，4下行
            let sData = data.devices.slave1.variables;
            item.faultStatus = sData.DI1 === 1 ? 20 : "";
            item.maintenanceStatus = sData.DI2 === 1 ? 20 : "";
            item.flow = sData.DI3 === 1 ? "10" : "";
            item.flow = sData.DI4 === 1 ? "01" : "";
          }
          global_callback(item);
        }
      });
    });
  },
};
