// 新时达电梯socket
import monitor from "@/utils/api/monitor";
var elevators = "";
var currentEle = "";
var global_callback = null;
var socket = null;
var stepSetData = {};
var tryTime = 0;
var timer = null;
export default {
  sendSock(agentData, callback) {
    console.log("新时达");
    // agentData=>socket所需参数 callback=>回调函数
    elevators = agentData;
    currentEle = elevators;
    global_callback = callback;
    monitor.getChecksumBystep().then((res) => {
      if (!res.success) {
        return false;
      }
      stepSetData = res.data;
      this.initSocket();
    });
    timer = setInterval(() => {
      this.getStepSetData();
    }, 1000 * 60 * 4);
  },
  getStepSetData() {
    monitor.getChecksumBystep().then((res) => {
      if (!res.success) {
        return false;
      }
      stepSetData = res.data;
    });
  },
  closeSocket() {
    if (socket && socket.readyState === 1) {
      socket.close();
      clearInterval(timer);
    }
  },
  initSocket() {
    let that = this;
    socket = new WebSocket("wss://ws.elevatorstar.com:9091/websocket");
    socket.onopen = function () {
      let postData = {
        id_nr: currentEle.regCode,
        data_type: "M1",
        appKey: stepSetData.appKey,
        nonce: stepSetData.nonce,
        curTime: stepSetData.curTime,
        checkSum: stepSetData.checkSum,
        version: 2,
      };
      socket.send(JSON.stringify(postData));
    };
    socket.onmessage = function (e) {
      var data = JSON.parse(e.data).d;
      that.setStepData(data);
    };
    socket.onerror = function () {
      that.socketError();
    };
    socket.onclose = function () {
      that.socketError();
    };
  },
  socketError() {
    // 重连
    tryTime++;
    if (tryTime < 3) {
      socket = null;
      this.initSocket();
    }
  },
  setStepData(data) {
    currentEle.runImg = this.runStepImgFunc(data);
    currentEle.floor = data.Car_Position;
    if (data.Car_Status === 0) {
      currentEle.flow = "";
    } else {
      currentEle.flow =
        data.Car_Direction == 1 ? "10" : data.Car_Direction == 2 ? "01" : "";
    }
    global_callback(currentEle);
  },
  runStepImgFunc(data) {
    if (data.Door_Switch_Status == 0) {
      // 关门
      if (currentEle.runImg && currentEle.runImg.indexOf("open") > -1) {
        return "elevator_big_closing.gif";
      } else {
        return "elevator_big_close.png";
      }
    } else if (data.Door_Switch_Status == 1) {
      // 开门
      if (currentEle.runImg && currentEle.runImg.indexOf("close") > -1) {
        return "elevator_big_opening.gif";
      } else {
        return "elevator_big_open.png";
      }
    }
  },
};
