// 贝思特socket
const url = "ws://bst.molian.tech:20000/ws";
var socket = null;
var elevators = "";
var currentEle = "";
var global_callback = null;
export default {
  sendSock(agentData, callback) {
    console.log("贝斯特");
    // agentData=>socket所需参数 callback=>回调函数
    elevators = agentData;
    currentEle = elevators;
    global_callback = callback;
    this.initSocket();
  },
  closeSocket() {
    if (socket && socket.readyState === 1) {
      socket.close();
    }
  },
  initSocket() {
    var that = this;
    socket = new WebSocket(url);
    socket.onopen = function () {
      var iCode = currentEle.intelHardwareNumber || "";
      var postData = {
        Code: "0001" + iCode,
        Types: [1, 2],
      };
      socket.send(JSON.stringify(postData));
    };
    socket.onmessage = function (e) {
      var data = e.data;
      data = typeof data == "string" ? JSON.parse(data) : data;
      if (data.Types == 1) {
        that.fillValueBest(data); //解析填充页面数据
      } else if (data.Types == 2) {
        that.fillValueBestCall(data); //解析呼梯数据
      }
      global_callback(currentEle);
    };
    socket.onerror = function () {
      that.initSocket();
    };
    socket.onclose = function () {};
  },
  fillValueBest(data) {
    var iCode = currentEle.intelHardwareNumber || "";
    var deviceSerial = "0001" + iCode;
    if (deviceSerial == data["Code"]) {
      var value = data.Data;
      //智能硬件注册码，贝思特 elevatorCode
      this.setFloorBest(value.Floor, deviceSerial);
      this.setStatusAndDirectionBest(value.Status, deviceSerial);
      this.dealWithRealSignalForBest(value.SignalLevel, deviceSerial);
    }
  },
  setFloorBest(value) {
    //楼层
    if (value == null || value > 160) {
      return;
    }
    if (currentEle.floorsList) {
      var flag = false;
      currentEle.floorsList.map((data) => {
        if (data[1] == value) {
          currentEle.floor = data[0];
          flag = true;
        }
      });
      if (!flag) {
        currentEle.floor = value;
      }
    } else {
      currentEle.floor = value;
    }
  },
  setStatusAndDirectionBest(value, deviceSerial) {
    value =
      "00000000000000000000000000000000" + parseInt(value, 16).toString(2);
    value = value.slice(value.length - 32); //转成二进制
    //判断运行方向
    var directionValue = value.substr(value.length - 4, 2); //上行下行
    this.setDirectionBest(directionValue, deviceSerial);
    var doorValue = value.substr(value.length - 8, 3); //门机位
    this.setDoorStatusForBest(doorValue, deviceSerial);
    var fixStatus = value.substr(value.length - 10, 1); //检修状态
    if (fixStatus == "1") {
      // this.statusImg[1].isFault = true;
    } else {
      // this.statusImg[1].isFault = false;
    }
    var errorStatus = value.substr(value.length - 20, 1); //故障状态
    if (errorStatus == 1) {
      // this.statusImg[0].isFault = true;
    } else {
      // this.statusImg[0].isFault = false;
    }
  },
  setDirectionBest(value) {
    //电梯行驶方向
    currentEle.flow = value == "01" ? "10" : value == "10" ? "01" : value;
  },
  setDoorStatusForBest(value) {
    if (value == "000") {
      currentEle.runImg = "elevator_big_close.png";
    } else if (value == "011") {
      currentEle.runImg = "elevator_big_closing.gif";
    } else if (value == "010") {
      currentEle.runImg = "elevator_big_open.png";
    } else if (value == "001") {
      currentEle.runImg = "elevator_big_opening.gif";
    }
    currentEle.runStatus = value; // 待定wyn
  },
  dealWithRealSignalForBest(data) {
    var signalType = "1"; //贝思特固定类型 3G 4G
    var signalValue = data;
    var signalNum = this.dealWithSignalStrength(signalType, signalValue);
    currentEle.signalIntensity = "icon_mobile_" + signalNum;
  },
  dealWithSignalStrength(signalType, signalValue) {
    //处理信号强度
    if (signalType == 4) {
      return 0;
    }
    var v =
      signalType == 3
        ? Math.round(((signalValue + 94) / 94) * 100)
        : Math.round((signalValue / 31) * 100);
    var gridNum = signalType == 3 ? 4 : 5,
      fullNum = 100;
    if (v <= 0) {
      return 0;
    } else if (v <= (fullNum / gridNum) * 1) {
      return 1;
    } else if (v <= (fullNum / gridNum) * 2 && v > (fullNum / gridNum) * 1) {
      return 2;
    } else if (v <= (fullNum / gridNum) * 3 && v > (fullNum / gridNum) * 2) {
      return 3;
    } else if (v <= (fullNum / gridNum) * 4 && v > (fullNum / gridNum) * 3) {
      return 4;
    } else if (
      v <= (fullNum / gridNum) * 5 &&
      v > (fullNum / gridNum) * 4 &&
      signalType != 3
    ) {
      return 5;
    }
    return 0;
  },
  fillValueBestCall() {
    // var  value = data.Data;
    // var UpCall = value.UpCall;
    // var DownCall = value.DownCall;
    // var OutCall = value.OutCall;
  },
};
