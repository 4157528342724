// 西班牙电梯socket
/* eslint-disable */
var elevators = "";
var currentEle = "";
var global_callback = null;
var socket = null;
export default {
  sendSock(agentData, callback) {
    // agentData=>socket所需参数 callback=>回调函数
    elevators = agentData;
    currentEle = elevators;
    global_callback = callback;
    this.initSocket();
  },
  closeSocket() {
    if (socket && socket.readyState === 1) {
      socket.close();
    }
  },
  initSocket() {
    let onDisconnect = "";
    let that = this;

    let user = "users.net4machines.clients.joylive.admin";
    let pass = "22joylive22";
    let deviceID = currentEle.intelHardwareNumber;
    let elementID = currentEle.elementId;
    let addr =
      "wss://iot.joylive.com:9443/?to=" +
      deviceID +
      ".v.n4m.zone:2324&method=n4m&user=" +
      user +
      "&pass=" +
      pass;

    let state = {};
    let subcb = function (res) {
      let parts = res.Topic.split(".");
      if (parts.length == 6) {
        let element = parts[parts.length - 3];
        let eventName = parts[parts.length - 2];
        let subElement = parts[parts.length - 1];
        let value = res.Value;
        if (eventName == "state") {
          let stateState = state[element][subElement][eventName];
          stateState[Math.abs(value)] = Math.sign(value);
          state[element][subElement][eventName] = stateState;
        } else {
          state[element][subElement][eventName] = value;
        }
      }
      that.setGeneralData(state);
    };
    socket = obbus.connect(addr).then((bus) => {
      bus.onDisconnect(onDisconnect);
      //This petition gives the current status of the elevator
      bus
        .rpcString(
          "smart@" + elementID + ".cmd.telemetry.status",
          "",
          {},
          11000
        )
        .then((res) => {
          let data = JSON.parse(res);
          Object.keys(data).forEach((k) => {
            state[k] = {};
            Object.keys(data[k]).forEach((subK) => {
              state[k][subK] = data[k][subK];
            });
          });
          that.setGeneralData(state);
        });
      // This subscription listens to the elevator status
      bus.subscribe("smart@" + elementID + ".evt.telemetry.*", subcb);
    });
  },
  setGeneralData(data) {
    var socketData = data.liftcontroller.cabin1;
    currentEle.floor = socketData.floor; // floor
    let front =
      typeof socketData.doors === "object"
        ? socketData.doors.front
        : eval("(" + socketData.doors + ")").front;
    currentEle.runImg = this.runImgFunc(front); // open or close image
    currentEle.flow =
      socketData.movement == 1 ? "10" : socketData.movement == -1 ? "01" : ""; // up or down
    global_callback(currentEle);
    // this.statusImg[0].isFault = socketData.state["8193"] == 1 ? true : false; //  Maintenance
    // this.statusImg[1].isFault = socketData.state["12288"] == 1 ? true : false; //  Generic Error
    // this.statusImg[2].isFault = socketData.state["8192"] == 1 ? true : false; //  Overload
  },
  runImgFunc(data) {
    switch (data) {
      case 2: // open
        return "elevator_big_open.png";
      case 1: // opening
        return "elevator_big_opening.gif";
      case -1: // closing
        return "elevator_big_closing.gif";
      case -2: // closed
        return "elevator_big_close.png";
      default:
        return;
    }
  },
};
/* eslint-disable no-new */
